<template>
  <div class="paid-detail-component">
    <div class="paid-name">有偿工单</div>
    <div class="paid-arrange">
      <div class="paid-arrange-sort">
        <div class="title">申请ID</div>
        <div class="content">{{ data.orderCode }}</div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">申请时间</div>
        <div class="content">
          {{ data.creationDate | dateFormat("YYYY-MM-DD HH:mm:ss") }}
        </div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">申请人</div>
        <div class="content">{{ data.ordPersonName }}</div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">手机号码</div>
        <div class="content">{{ data.ordPersonPhone }}</div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">报修位置</div>
        <div class="content">{{ data.positionDescName }}</div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">收费类型</div>
        <div class="content">{{ data.settlementTypeStr }}</div>
      </div>
      <div class="paid-arrange-setWidth paid-arrange-sort">
        <div class="title">收费金额</div>
        <div class="content">{{ data.totalAmount || "0.00" }}￥</div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">支付时间</div>
        <div class="content">{{ data.payDate || "-" }}</div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">微信支付单号</div>
        <div class="content">{{ data.transactionId || "-" }}</div>
      </div>
      <div class="paid-arrange-setWidth paid-arrange-sort">
        <div class="title">支付金额</div>
        <div class="content">{{ data.totalAmount || "0.00" }}￥</div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">开票记录编号</div>
        <div class="content">{{ data.invoiceBillNo || "-" }}</div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">开发票状态</div>
        <div class="content">{{ data.invoiceStatusStr || "-" }}</div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">开票时间</div>
        <div class="content">{{ data.invoiceDate || "-" }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// import { queryOrderById } from "@/api/ruge/gsPark/customerService/paidWorkOrder";
import {
  queryOrderById
} from "@/api/ruge/lego/paymentInvoice/paidWorkOrder"
export default {
  name: "paidPayDetail",
  data() {
    return { data: {} };
  },
  created() {
    this.getTableList();
  },
  methods: {
    getTableList() {
      queryOrderById({
        orderId: this.$route.query.orderId,
      }).then((res) => {
        if (res) {
          this.data = res;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.paid-detail-component {
  padding: 32px;
  margin: 32px;
  background: #fff;
  height: 100%;
  .paid-name {
    width: 96px;
    height: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #2f3941;
    line-height: 20px;
    margin-bottom: 24px;
  }
  .paid-arrange {
    display: flex;
    flex-flow: row wrap;
    width: 874px;
    .paid-arrange-setWidth {
      width: 874px !important;
    }
    .paid-arrange-sort {
      width: 436px;
      height: 42px;
      margin-bottom: 24px;
      .title {
        height: 18px;
        font-size: 12px;
        font-weight: 300;
        color: #2f3941;
        line-height: 18px;
      }
      .content {
        margin-top: 4px;
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #2f3941;
        line-height: 20px;
      }
    }
  }
}
</style>